/* hero */
.card-hero{
    height: 100vh;
  }
  .bg-blur{
      filter: blur(6px);
      background: linear-gradient(0deg, rgba(238,174,202,0) 0%, rgba(246,107,14,1) 50%, rgba(148,187,233,0) 100%);
  }
  .card-hero img{
    object-fit: cover !important;
  }
  /* main-home */
  .main-home{
    background-color: var(--gray-placeholder);
  }
  .navbar{
    z-index: 9999;
  }
  .hero-logo{
    font-size: 70px;
  }
  .name-club{
    color: var(--orange) !important;
    padding-bottom: 13px;
  }
  .name-club label{
    width: 100%;
    height: 2px;
    background-color: var(--orange);
    bottom: 0; 
    left: 0;
  }
  .hero-logo img{
    width: 150px;
  }
  .lets-start{
    background-color: var(--orange);
    position: absolute;
    right: -250px;
    bottom: 0;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 250px;
    border-left: 4px solid #fff !important;
  }