.active{
    color: var(--orange) !important;
    font-weight: 700 !important;
}
.nav-item a{
    cursor: pointer;
}
.fixTrue{
    position: fixed;
    background-color: var(--gray);
    left: 0px;
    width: 100%;
    border-bottom: 1px solid rgba(246, 107, 14, 0.4);
}
.fixFalse{
    position: static;
}