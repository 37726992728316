.pop-head-programe::after{
    content: "";
    position: absolute;
    top: -160px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: var(--orange);
    z-index: -1;
  }
  .cards-programs > div{
    width: 100%;
    height: 120px;
    background-color: var(--white);
    padding: 10px;
  }
  .cards-programs > div span{
    width: 50px;
    height: 50px;
    background-color: var(--orange);
    position: absolute;
    top: -25px !important;
    right: -25px !important;
  }
  .circle-programe{
    width: 25px;
    height: 25px;
    background-color: var(--orange);
  }
  .head-subprograme{
    border-bottom: 4px solid white;
    display: inline-block;
  }