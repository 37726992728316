.head-coache-mian{
    background-color: var(--orange);
  }
  .list-coache{
    border-bottom: 1px solid var(--orange);
  }
  .list-coche-social > li >span{
    cursor: pointer;
  }
  .arrow-coache{
    color: var(--orange);
    cursor: pointer;
  }
  .box-img-one{
    top: -8px;
    left: 40px;
    width: 150px;
    height: 150px;
    background-color: var(--gray);
  }
  .box-img-tow{
    bottom: -8px;
    right: 40px;
    width: 150px;
    height: 150px;
    background-color: var(--gray);
  }