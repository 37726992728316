.head-plain{
    background-color: var(--orange);
  }
  .plain-icon{
    width: 100px;
    height: 100px;
    background-color: var(--white);
    border-bottom: 4px solid var(--orange);
    font-size: 50px;
    color:var(--orange)
  }
  .box-plain{
    min-height: 400px;
  }
  .plain-price{
    width: 120px;
    height: 80px;
    background-color: var(--orange);
    font-size: 40px;
    color: var(--white);
    top: 50px !important;
  }
  .subicon-plain{
    color: var(--orange);
  }
  .btn-plain{
    background-color: var(--orange);
  }