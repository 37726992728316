.side-life-club{
    height: 100vh;
    width: 100%;
  }
  .list-hash{
    cursor: pointer;
  }
  .hash-life-club{
    color:var(--orange);
  }
  .life-cards .col-md-6,
  .life-cards .col-12,
  .life-cards .col-6,
  .life-cards .col-4{
    padding: 0 !important;
  }
  .life-card > div{
    height: 300px; 
  }
  .life-card > div > img{
    object-fit: cover;
  }
  .placeholder-life{
    background-color: var(--gray-placeholder);
    color:var(--orange);
    transform: scale(0);
    transition: all 0.3s ease 0.1s;
  }
  .life-card:hover .placeholder-life{
    transform: scale(1);
  }
  .navbar-toggler:focus{
    box-shadow:none;
  }