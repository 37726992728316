.head-health-body{
    color:var(--gray);
  }
  .head-health-body span{
    color: var(--orange) !important;
  }
  .main-image-health-body{
    width: 350px;
  }
  .card-health-body > div{
    width: 100%;
    height: 230px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .card-health-body > div > span{
    color: var(--orange);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 150px;
    opacity: 0.8;
    z-index: 10;
  }