:root{
  --white:#fff;
  --gray:#1B1A17;
  --gray-placeholder:#1b1a17ec;
  --orange:#F66B0E;
  --orange-placeholder:#f66b0e91;
}
::-webkit-scrollbar{width:  4px;}
::-webkit-scrollbar-track{background-color: white ;}
::-webkit-scrollbar-thumb{background-color:  var(--orange) ;border-radius: 50px;}

body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
